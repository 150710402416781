.bannerBox {
  position: relative;
  z-index: 2;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.bannerContainer {
  position: relative;
  height: 500px;
  width: 100vw;
  overflow: visible;
  z-index: 0;
}
